import * as Clickable from "components/clickable"
import Layout from "components/layout"
import { Typography } from "components/typography"
import { Link } from "gatsby"
import React, { useEffect, useRef, useState } from "react"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { JsonLd } from "react-schemaorg"
import { HowTo } from "schema-dts"
import { DataCollector, DataCollectorAction } from "@sog/sdk"

const StepsCarousel = () => {
  const images = ["/images/Step_1.png", "/images/Step_2.png", "/images/Step_3.png"]
  const timerRef = useRef()
  const [activeStep, setActiveStep] = useState(0)

  useEffect(() => {
    // componentDidMount
    autoTransfer()
    return () => {
      // componentWillUnmount
      if (timerRef.current) clearTimeout(timerRef.current)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const autoTransfer = () => {
    if (timerRef.current) clearTimeout(timerRef.current)
    timerRef.current = setTimeout(() => {
      setActiveStep(activeStep => (activeStep === images.length - 1 ? 0 : activeStep + 1))
      autoTransfer()
    }, 4000)
  }

  const stepAction = index => {
    setActiveStep(index)
    autoTransfer()
  }
  return (
    <div className="stepscarousel-root">
      <div className="img-container">
        {images.map((item, index) => {
          return <LazyLoadImage key={index} className="img stepscarousel-animation" style={{ opacity: index === activeStep ? 1 : 0 }} src={item} alt="" />
        })}
      </div>

      <div className="stepscarousel-bottom-view ">
        <div className="container-center">
          {images.map((item, index) => {
            const style = index === activeStep ? "stepscarousel-button" : "stepscarousel-button stepscarousel-button-active"
            return (
              <div key={index} className={style} role="button" tabIndex={index} onKeyDown={() => stepAction(index)} onClick={() => stepAction(index)}>
                {index + 1}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default function HowItWorks() {
  const getListView = () => {
    const rowsData = [
      {
        icon: "/images/icon_card.png",
        primary: "Tell us about your credit card",
        secondary: "Answer a few simple questions about your credit card and banking relationships. We use this information to get you a better deal.",
      },
      {
        icon: "/images/icon_search.png",
        primary: "We search for a better deal",
        secondary: "We instantly search for a better deal with your current bank, and across the market.",
      },
      {
        icon: "/images/icon_save.png",
        primary: "See what you could save",
        secondary: "Access a better deal from your bank, and leading offers from across the market. Compare what you could save and additional rewards you could earn. Choose to ‘stay’ or ‘go’ and begin saving.",
      },
    ]
    return (
      <div>
        <JsonLd<HowTo>
          item={{
            "@context": "https://schema.org",
            "@type": "HowTo",
            name: "How to Get a Better Credit Card Deal",
            estimatedCost: "Free",
            totalTime: "PT60S",
            supply: "Your credit card details, including provider, product name, and how you typically use it",
            tool: "Internet browser",
            step: rowsData.map((data, i) => {
              return {
                "@type": "HowToStep",
                name: data.primary,
                text: data.secondary,
                image: data.icon,
                url: "credit-cards/instructions",
              }
            }),
          }}
        />
        {rowsData.map((item, i) => {
          return (
            <div key={i} className="container-horizontal howitworks-row">
              <div className="howitworks-row-left">
                <div className="img-container">
                  {/* TODO: style={{ top: "40%" }} is an ugly fix to keep the icons align to top */}
                  <LazyLoadImage className="img" src={item.icon} style={{}} alt="" />
                </div>
              </div>
              <div className="howitworks-row-right">
                <Typography.H4>{item.primary}</Typography.H4>
                <Typography.P>{item.secondary}</Typography.P>
              </div>
            </div>
          )
        })}
      </div>
    )
  }

  return (
    <Layout title="Three simple steps to a better credit card | Stay or Go" description="Tell us about your credit card. We negotiate a better deal with your bank, and compare the best deals from across the market. Stay or Go - its your decision." image="/images/Step_3.png">
      <div className="page-container">
        <div className="howitworks-content-view">
          <Typography.H1>How it works</Typography.H1>
          <Typography.H2 comp="light">Three simple steps to a better credit card.</Typography.H2>
          <div className="container-horizontal container--space-between">
            <div className="howitworks-desktop-left">{getListView()}</div>
            <div className="howitworks-desktop-right show-for-desktop">
              <StepsCarousel />
            </div>
          </div>
          <div className="container-horizontal howitworks-row">
            <div className="howitworks-row-left">
              <div className="img-container">
                <LazyLoadImage className="img" style={{ top: "40%" }} alt="" />
              </div>
            </div>
            <div className="howitworks-row-right">
              <Clickable.Text
                comp={1}
                component={Link}
                to={"/credit-cards/instructions/"}
                className="landing-page-button"
                style={{ marginTop: "60px" }}
                onClick={() => {
                  DataCollector.getInstance().addAction(DataCollectorAction.GET_STARTED, { buttonId: "HOW_IT_WORKS" })
                }}
              >
                Get Started
              </Clickable.Text>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
